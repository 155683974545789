import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";

//API imports
import axios from "axios";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

//component-import
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import FormHeading from "../../UI/FormHeading/FormHeading";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import ToggleField from "../../UI/ToggleField/ToggleField";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponsePaymentChannel from "./ResponsePaymentChannel/ResponsePaymentChannel";
import NavigationContainer from "./NavigationContainer";

// styles import
import "./PaymentChannelMapping.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const PaymentChannelMapping = ({ companyDetails }) => {
  //& provider Options state
  const [providerOptions, setProviderOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyURNOptions, setCompanyURNOptions] = useState([]);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [isURNLoading, setIsURNLoading] = useState(false);

  //& payment channel API response
  const [paymentChannelAPIResponse, setPaymentChannelAPIResponse] =
    useState(null);

  //& loading state for provider
  const [isGetProviderLoading, setIsGetProviderLoading] = useState(false);

  // Add new function to fetch companies
  const getCompanyOptions = () => {
    setIsCompanyLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint
      // { cancelToken: source.token }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.company_id,
          label: item.common_name,
        }));
        setCompanyOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching companies:", error);
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg="Error fetching companies" />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => setIsCompanyLoading(false));
  };

  // Add function to fetch URNs based on company
  const getCompanyBasedConsumerURN = (companyId) => {
    setIsURNLoading(true);
    formik.setFieldValue("consumer_urn", "");
    setCompanyURNOptions([]);

    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_BASED_CONSUMERS_URN.baseUrl +
        apiEndpointList.COMPANY_BASED_CONSUMERS_URN.endpoint,
      { target_company_id: +companyId }
      // { cancelToken: source.token }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.consumer_urn,
          label: item.consumer_urn,
        }));
        setCompanyURNOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching URNs:", error);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg="Error fetching consumer URNs"
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => setIsURNLoading(false));
  };

  const fetchProviders = () => {
    setIsGetProviderLoading(true);

    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_PROVIDERS_LIST.baseUrl +
        paAPIEndpoints.FETCH_PROVIDERS_LIST.endpoint,
      {}
      // { cancelToken: source.token }
    )
      .then((response) => {
        const options = response.data.map((provider) => ({
          label: provider.display_name,
          value: provider.four_character_bank_code,
        }));
        setIsGetProviderLoading(false);

        setProviderOptions(options);
        // Yes Bank as default
        formik.setFieldValue("provider_code", "yesb");
      })
      .catch((error) => {
        setIsGetProviderLoading(false);
      });
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  const handleSubmit = () => {
    setPaymentChannelAPIResponse(null);

    const providerData = Object.entries({
      provider_code: formik.values.provider_code,
      is_upi: formik.values.is_upi,
      is_upi_autopay: formik.values.is_upi_autopay,
      is_enach: formik.values.is_enach,
      is_ecollect: formik.values.is_ecollect,
      is_block_push_transaction: formik.values.is_block_push_transaction,
      ecollect_code: formik.values.ecollect_code,
      enach_utility_code: formik.values.enach_utility_code,
    }).reduce((acc, [key, value]) => {
      if (value !== "" && value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const payload = {
      company_id: +formik.values.company_id.value,
      consumer_urn: formik.values.consumer_urn,
      providers: [providerData],
    };

    // submit loader state
    formik.setSubmitting(true);

    APIConfig.API_Client.put(
      paAPIEndpoints.PAYMENT_CHANNEL_SETUP.baseUrl +
        paAPIEndpoints.PAYMENT_CHANNEL_SETUP.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setPaymentChannelAPIResponse(response);

        ReactDOM.render(
          <SnackbarMessage msgtype="Success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        if (response.status >= 200) {
          // submit loader state
          formik.setSubmitting(false);
        }
      })
      .catch((error) => {
        // submit loader state
        formik.setSubmitting(false);

        setPaymentChannelAPIResponse(error);

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      });
  };
  const formik = useFormik({
    initialValues: {
      company_id: "",
      consumer_urn: "",
      provider_code: "",
      is_upi: false,
      is_upi_autopay: false,
      is_enach: false,
      is_ecollect: false,
      is_block_push_transaction: false,
      ecollect_code: "",
      enach_utility_code: "",
    },
    validationSchema: Yup.object().shape({
      company_id: Yup.string().required("Company ID is required"),
      consumer_urn: Yup.string().required("Consumer URN is required"),
      provider_code: Yup.string().required("Provider is required"),
      ecollect_code: Yup.string().when("is_ecollect", {
        is: true,
        then: () =>
          Yup.string().required(
            "eCollect Code is required when eCollect is enabled"
          ),
        otherwise: () => Yup.string(),
      }),
      enach_utility_code: Yup.string().when("is_enach", {
        is: true,
        then: () =>
          Yup.string().required(
            "eNACH Utility Code is required when eNACH is enabled"
          ),
        otherwise: () => Yup.string(),
      }),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  // Function to check if any toggle is enabled
  const isAnyToggleEnabled = () => {
    return (
      formik.values.is_upi ||
      formik.values.is_upi_autopay ||
      formik.values.is_enach ||
      formik.values.is_ecollect ||
      formik.values.is_block_push_transaction
    );
  };

  // Function to check if required fields are filled
  const areRequiredFieldsFilled = () => {
    const hasRequiredFields =
      formik.values.company_id &&
      formik.values.consumer_urn &&
      formik.values.provider_code;
    // If eCollect is enabled and its code is filled
    if (formik.values.is_ecollect && !formik.values.ecollect_code) {
      return false;
    }

    // If eNACH is enabled and its utility code is filled
    if (formik.values.is_enach && !formik.values.enach_utility_code) {
      return false;
    }
    return hasRequiredFields;
  };

  const isSubmitEnabled = () => {
    return (
      isAnyToggleEnabled() && areRequiredFieldsFilled() && !formik.isSubmitting
    );
  };

  //* Prefetch e-collect code
  const fetchECollectCode = (bankCode) => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_ECOLLECT_CODE.baseUrl +
        paAPIEndpoints.FETCH_ECOLLECT_CODE.endpoint,
      {
        provider_code: bankCode,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        formik.setFieldValue(
          "ecollect_code",
          response?.data[0]?.e_collect_code
        );
      })
      .catch((error) => {
        console.log("fetchECollectCode ~ error:", error);
      });
  };

  useEffect(() => {
    if (formik?.values?.provider_code) {
      fetchECollectCode(formik.values.provider_code);
    }
  }, [formik.values.provider_code]);

  // Modify useEffect to fetch companies if no companyDetails
  useEffect(() => {
    getCompanyOptions();
  }, [companyDetails]);

  useEffect(() => {
    if (formik?.values?.company_id?.value) {
      getCompanyBasedConsumerURN(formik?.values?.company_id?.value);
    }
  }, [formik?.values?.company_id?.value]);

  const navigateHandler = () => {
    setPaymentChannelAPIResponse(null);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form className="payment-channel-form">
          <div className="ui-form-details">
            <div className="ui-form-content">
              <FormHeading headingText="Payment Channel Mapping" />
              <div className="ui-form-inputs-section">
                <TextFieldSelect
                  id="company_id"
                  name="company_id"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("company_id", selectedOption)
                  }
                  onBlur={() => formik.setFieldTouched("company_id", true)}
                  value={formik.values.company_id}
                  options={companyOptions}
                  noOptionsMessage={() => "No such company exists"}
                  label="Company"
                  required={true}
                  isLoading={isCompanyLoading}
                  placeholder="Select company name"
                  isformatOptionLabel={true}
                  showOnlyLabelWhenSelected={true}
                />

                <TextFieldSelect
                  id="consumer_urn"
                  name="consumer_urn"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("consumer_urn", selectedOption)
                  }
                  onBlur={() => formik.setFieldTouched("consumer_urn", true)}
                  value={formik.values.consumer_urn}
                  options={companyURNOptions}
                  noOptionsMessage={() =>
                    `No such consumer URN exists for ${formik?.values?.company_name?.label}`
                  }
                  label="Consumer URN"
                  required={true}
                  isLoading={isURNLoading}
                  placeholder="Select consumer URN"
                  isformatOptionLabel={false}
                  isDisabled={!formik?.values?.company_id?.value ? true : false}
                />
              </div>
              <hr />
              <div className="ui-form-inputs-section">
                <TextFieldSelect
                  id="provider_code"
                  name="provider_code"
                  options={providerOptions} // Using dynamic options here
                  noOptionsMessage={() => "No provider exists"}
                  placeholder="Select Provider"
                  label="Provider"
                  value={
                    providerOptions.find(
                      (option) => option.value === formik.values.provider_code
                    ) || null
                  }
                  onChange={(option) => {
                    formik.setFieldValue(
                      "provider_code",
                      option ? option.value : ""
                    );
                  }}
                  isLoading={isGetProviderLoading}
                  required
                />
              </div>

              <div className="ui-form-inputs-section">
                <div className="ui-toggle-group">
                  <ToggleField
                    id="is_upi"
                    label="UPI"
                    checked={formik.values.is_upi}
                    onChange={(e) =>
                      formik.setFieldValue("is_upi", e.target.checked)
                    }
                  />
                  <ToggleField
                    id="is_upi_autopay"
                    label="UPI Autopay"
                    checked={formik.values.is_upi_autopay}
                    onChange={(e) =>
                      formik.setFieldValue("is_upi_autopay", e.target.checked)
                    }
                  />
                  <ToggleField
                    id="is_enach"
                    label="eNACH"
                    checked={formik.values.is_enach}
                    onChange={(e) =>
                      formik.setFieldValue("is_enach", e.target.checked)
                    }
                  />
                  <ToggleField
                    id="is_ecollect"
                    label="eCollect"
                    checked={formik.values.is_ecollect}
                    onChange={(e) =>
                      formik.setFieldValue("is_ecollect", e.target.checked)
                    }
                  />
                  <ToggleField
                    id="is_block_push_transaction"
                    label="Block Push Transaction"
                    checked={formik.values.is_block_push_transaction}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "is_block_push_transaction",
                        e.target.checked
                      )
                    }
                  />
                </div>
              </div>
              <div className="ui-form-inputs-section">
                <TextFieldInput
                  id="ecollect_code"
                  name="ecollect_code"
                  value={formik.values.ecollect_code}
                  onChange={formik.handleChange}
                  label="eCollect Code"
                  required={formik.values.is_ecollect}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.ecollect_code}
                  error={formik.errors.ecollect_code}
                />
                <TextFieldInput
                  id="enach_utility_code"
                  name="enach_utility_code"
                  value={formik.values.enach_utility_code}
                  onChange={formik.handleChange}
                  label="eNACH Utility Code"
                  required={formik.values.is_enach}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.enach_utility_code}
                  error={formik.errors.enach_utility_code}
                />
              </div>
            </div>
            <div className="ui-channel-button-container">
              <button
                className={isSubmitEnabled() ? "active" : "disabled"}
                type="button"
                onClick={handleSubmit}
                disabled={!isSubmitEnabled()}
              >
                {formik.isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>

      {/* Navigation container to DMO form */}
      {/* {paymentChannelAPIResponse?.status >= 200 &&
      paymentChannelAPIResponse?.status < 300 ? (
        <NavigationContainer
          submitHandler={navigateHandler}
          btnText="Back to DMO"
          messageText="Payment Channel is successfully done"
        />
      ) : null} */}

      {/* Response Block */}
      <ResponsePaymentChannel
        response={
          paymentChannelAPIResponse?.response?.data ||
          paymentChannelAPIResponse?.response?.data?.data ||
          paymentChannelAPIResponse?.data ||
          paymentChannelAPIResponse?.data?.data
        }
        status={paymentChannelAPIResponse?.status}
        copyableKeys={["decentro_txn_id", "message"]} // Only these fields will have copy buttons
      />
    </>
  );
};

export default PaymentChannelMapping;
